<script>
import Swal from 'sweetalert2';
export default {
  props: {
    meetingRooms: {
      type: Array,
      default: function() {
        return [];
      }
    }
  },
  methods: {
    deleteMeetingRoom(roomName, roomUid) {
      Swal.fire({
        title: "Êtes-vous sûr de supprimer " + roomName + "?",
        text: "Vous ne pourrez pas annuler cela!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#34c38f",
        cancelButtonColor: "#f46a6a",
        confirmButtonText: "Supprimer!",
      }).then((result) => {
        if (result.value) {
          this.$http
            .post("/infrastructure/meeting_room/delete/" + roomUid)
            .then((res) => {
              var status = res.data.original.status;
              switch (status) {
                case 200:
                  Swal.fire("Félicitations!", res.data.original.msg, "success");
                  // _this.refreshTable();
                  break;

                case 500:
                  Swal.fire("Avertissement!", res.data.original.msg, "warning");
                  break;
              }
            })
            .catch((error) => {
              this.$toast.error(error.message);
            })
            .finally(() => {});
        }
      });
    },
  },
  data() {
    return {
      showModal: false
    };
  }
};
</script>

<template>
  <div class="table-responsive mb-0">
    <table class="table table-centered table-nowrap">
      <thead class="thead-light">
        <tr>
          <th>Désignation</th>
          <th>Location</th>
          <th>Capacité(personnes)</th>
          <th>Action</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="data in meetingRooms" :key="data.id">
          <td>{{data.designation}}</td>
          <td>{{data.location}}</td>
          <td>{{data.total_capacity}}</td>
          <td>
            <router-link
            v-if="$can('update_administration_meeting_room')"
              :to="{
                name: 'base.infrastructures.meeting_rooms.edit',
                params: { uid: data.uuid },
              }"
              class="mr-3 text-primary"
              data-toggle="tooltip"
              data-placement="top"
              title=""
              data-original-title="Edit"
              ><i class="mdi mdi-pencil font-size-18"></i
            ></router-link>
            <a v-if="$can('delete_administration_meeting_room')" @click="deleteMeetingRoom(data.designation, data.uuid)" class="text-danger" data-toggle="tooltip" data-placement="top" title="" data-original-title="Delete"><i class="mdi mdi-close font-size-18"></i></a>
          </td>
        </tr>
      </tbody>
    </table>
    
  </div>
</template>